import React, { Component } from "react";
import { Link } from "react-scroll";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowForward from "@material-ui/icons/ArrowForward";

const styles = theme => ({
  background: {
    background: "black",
    color: "white"
  },
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      alignItems: "start"
    }
  },
  link: {
    alignItems: "center",
    border: "3px solid white",
    borderRadius: "5px",
    color: "white",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.25rem",
    justifyContent: "center",
    padding: "1rem",
    "&:hover": {
      background: "red",
      border: "3px solid red"
    }
  },
  spanRed: {
    color: "red",
    fontWeight: "bold"
  },
  spanWhite: {
    color: "white",
    fontWeight: "bold"
  },
  typography1: {
    marginBottom: "1vh",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  typography2: {
    marginBottom: "3vh",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  typography3: {
    marginBottom: "6vh",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  }
});

class AboutView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: <ArrowForward />
    };
  }

  setIcon = () => {
    this.setState({
      icon: <ArrowForward />
    });
  };

  setIconHover = () => {
    this.setState({
      icon: <ArrowDownward />
    });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.background} id={this.props.id}>
        <Container maxWidth="md" className={classes.container}>
          <Typography variant="h4" className={classes.typography1}>
            Hello, my name is{" "}
            <span className={classes.spanRed}>Adish Thapa.</span>
          </Typography>
          <Typography variant="h4" className={classes.typography2}>
            I'm a Full-Stack Developer from Raleigh, NC.
          </Typography>
          <Typography variant="h6" className={classes.typography3}>
            I create <span className={classes.spanWhite}>intuitive</span>,{" "}
            <span className={classes.spanWhite}>responsive </span> and{" "}
            <span className={classes.spanWhite}>fast</span> web applications
            with <span className={classes.spanWhite}>powerful</span> User
            Interfaces and <span className={classes.spanWhite}>modern</span>{" "}
            Infrastructures.
          </Typography>

          <Link
            activeClass="active"
            to="portfolioView"
            spy={true}
            smooth={true}
            offset={-63}
            duration={500}
            className={classes.link}
            onMouseEnter={this.setIconHover}
            onMouseLeave={this.setIcon}
          >
            VIEW PORTFOLIO&nbsp;&nbsp;{this.state.icon}
          </Link>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(AboutView);
