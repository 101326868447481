import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { enterExitStyle, SpringGrid } from "react-stonecutter";
import { CategoryLinks, CategoryLinksResponsive } from "../components/Link";
import Modal from "../components/Modal";
import { JavaScriptCheckbox, PythonCheckbox } from "../components/Checkbox";
import projects from "../projects.json";

const styles = theme => ({
  background: {
    background: "white",
    color: "black"
  },
  caption: {
    fontWeight: "900"
  },
  card: {
    position: "relative"
  },
  category: {
    color: "grey",
    margin: "7vh 0px 1vh 0px"
  },
  container: {
    minHeight: "calc(100vh - 63px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    [theme.breakpoints.up("md")]: {
      alignItems: "start"
    }
  },
  dialog: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  filter: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "2vh"
  },
  gridBottom: { marginBottom: "10vh" },
  gridTop: { marginTop: "7vh" },
  image: {
    cursor: "pointer",
    width: "275px"
  },
  imageBackground: {
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "red",
    bottom: "5%",
    color: "white",
    display: "flex",
    height: "50px",
    justifyContent: "center",
    margin: "0px 5%",
    position: "absolute",
    width: "90%"
  },
  option: {
    color: "black",
    cursor: "pointer",
    textAlign: "left",
    "&:hover": {
      color: "red",
      fontWeight: "900",
      textDecoration: "none"
    }
  },
  optionActive: {
    color: "red",
    cursor: "pointer",
    textAlign: "left",
    fontWeight: "900",
    "&:hover": {
      textDecoration: "none"
    }
  },
  title: {
    marginBottom: "5vh",
    textDecoration: "underline"
  },
  titleSm: {
    marginBottom: "3vh",
    textDecoration: "underline"
  }
});

class PortfolioView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: "featured",
      activeProject: {},
      nodejs: true,
      react: true,
      vanillajs: true,
      django: true,
      vanillapython: true,
      allProjects: projects,
      featuredProjects: projects.filter(project => project.featured === true),
      javascriptProjects: projects.filter(project =>
        project.languages.includes("javascript")
      ),
      pythonProjects: projects.filter(project =>
        project.languages.includes("python")
      ),
      dialogOpen: false
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked }, function() {
      if (this.state.activeLink === "javascript") {
        if (!this.state.nodejs && !this.state.react && !this.state.vanillajs) {
          this.setState({ javascriptProjects: [] });
        } else if (
          !this.state.nodejs &&
          this.state.react &&
          this.state.vanillajs
        ) {
          this.setState({
            javascriptProjects: projects.filter(
              project =>
                project.frameworks.includes("react") ||
                project.frameworks.includes("vanillajs")
            )
          });
        } else if (
          this.state.nodejs &&
          !this.state.react &&
          this.state.vanillajs
        ) {
          this.setState({
            javascriptProjects: projects.filter(
              project =>
                project.frameworks.includes("nodejs") ||
                project.frameworks.includes("vanillajs")
            )
          });
        } else if (
          this.state.nodejs &&
          this.state.react &&
          !this.state.vanillajs
        ) {
          this.setState({
            javascriptProjects: projects.filter(
              project =>
                project.frameworks.includes("nodejs") ||
                project.frameworks.includes("react")
            )
          });
        } else if (
          !this.state.nodejs &&
          !this.state.react &&
          this.state.vanillajs
        ) {
          this.setState({
            javascriptProjects: projects.filter(project =>
              project.frameworks.includes("vanillajs")
            )
          });
        } else if (
          this.state.nodejs &&
          !this.state.react &&
          !this.state.vanillajs
        ) {
          this.setState({
            javascriptProjects: projects.filter(project =>
              project.frameworks.includes("nodejs")
            )
          });
        } else if (
          !this.state.nodejs &&
          this.state.react &&
          !this.state.vanillajs
        ) {
          this.setState({
            javascriptProjects: projects.filter(project =>
              project.frameworks.includes("react")
            )
          });
        } else {
          this.setState({
            javascriptProjects: projects.filter(
              project =>
                project.frameworks.includes("nodejs") ||
                project.frameworks.includes("react") ||
                project.frameworks.includes("vanillajs")
            )
          });
        }
      } else if (this.state.activeLink === "python") {
        if (!this.state.django) {
          this.setState({ pythonProjects: [] });
        } else {
          this.setState({
            pythonProjects: projects.filter(project =>
              project.frameworks.includes("django")
            )
          });
        }
      }
    });
  };

  handleClick = event => {
    event.preventDefault();
    this.setState({ activeLink: event.target.id });
  };

  handleClickButton = event => {
    event.preventDefault();
    console.log(event.target);
    this.setState({ activeLink: event.target.id });
  };

  handleDialogOpen = event => {
    event.preventDefault();
    this.setState({
      activeProject: projects.find(project => project.name === event.target.id),
      dialogOpen: true
    });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  populateJavaScriptFrameworks = () => {
    return (
      <JavaScriptCheckbox
        nodejs={this.state.nodejs}
        react={this.state.react}
        vanillajs={this.state.vanillajs}
        handleChange={this.handleChange}
      />
    );
  };

  populatePythonFrameworks = () => {
    return (
      <PythonCheckbox
        django={this.state.django}
        vanillapython={this.state.vanillapython}
        handleChange={this.handleChange}
      />
    );
  };

  populateProjects = activeLink => {
    const classes = this.props.classes;
    let projects;
    switch (activeLink) {
      case "all":
        projects = this.state.allProjects;
        break;
      case "featured":
        projects = this.state.featuredProjects;
        break;
      case "javascript":
        projects = this.state.javascriptProjects;
        break;
      case "python":
        projects = this.state.pythonProjects;
        break;
      default:
        projects = this.state.featuredProjects;
    }
    return projects.map(project => (
      <div key={project.id}>
        <Card className={classes.card}>
          <Link onClick={this.handleDialogOpen}>
            <CardMedia
              id={project.name}
              component="img"
              image={project.image}
              alt={project.name}
              height="220px"
              className={classes.image}
            />
          </Link>
          <div className={classes.imageBackground}>
            <Typography
              align="center"
              display="block"
              variant="body1"
              className={classes.caption}
            >
              {project.name}
            </Typography>
          </div>
        </Card>
      </div>
    ));
  };

  render() {
    const classes = this.props.classes;
    const { enter, entered, exit } = enterExitStyle.simple;

    return (
      <div className={classes.background} id={this.props.id}>
        <Container maxWidth="md" className={classes.container}>
          <Hidden xsDown implementation="css">
            <Grid container spacing={0} className={classes.gridTop}>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  display="block"
                  variant="h3"
                  className={classes.title}
                >
                  PROJECTS
                </Typography>
              </Grid>

              <Grid item xs={5} md={4}>
                <CategoryLinks
                  activeLink={this.state.activeLink}
                  allProjects={this.state.allProjects}
                  handleClick={this.handleClick}
                  populateJavaScriptFrameworks={
                    this.populateJavaScriptFrameworks
                  }
                  populatePythonFrameworks={this.populatePythonFrameworks}
                />
              </Grid>
              <Grid item xs={7} md={8} className={classes.gridBottom}>
                <SpringGrid
                  columns={isWidthUp("md", this.props.width) ? 2 : 1}
                  columnWidth={275}
                  gutterWidth={20}
                  gutterHeight={20}
                  itemHeight={220}
                  springConfig={{ stiffness: 60, damping: 14 }}
                  enter={enter}
                  entered={entered}
                  exit={exit}
                >
                  {this.populateProjects(this.state.activeLink)}
                </SpringGrid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp implementation="css">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.gridTop}
            >
              <Grid item xs={12}>
                <Typography
                  align="center"
                  display="block"
                  variant="h3"
                  className={classes.titleSm}
                >
                  PROJECTS
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.filter}>
                <CategoryLinksResponsive
                  activeLink={this.state.activeLink}
                  allProjects={this.state.allProjects}
                  handleClick={this.handleClickButton}
                  populateJavaScriptFrameworks={
                    this.populateJavaScriptFrameworks
                  }
                  populatePythonFrameworks={this.populatePythonFrameworks}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridBottom}>
                <SpringGrid
                  columns={isWidthUp("md", this.props.width) ? 2 : 1}
                  columnWidth={275}
                  gutterWidth={20}
                  gutterHeight={20}
                  itemHeight={220}
                  springConfig={{ stiffness: 60, damping: 14 }}
                  enter={enter}
                  entered={entered}
                  exit={exit}
                >
                  {this.populateProjects(this.state.activeLink)}
                </SpringGrid>
              </Grid>
            </Grid>
          </Hidden>
          <Modal
            activeProject={this.state.activeProject}
            dialogOpen={this.state.dialogOpen}
            handleDialogClose={this.handleDialogClose}
          />
        </Container>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(PortfolioView));
