import React, { Component } from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider
} from "@material-ui/core/styles";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import AboutView from "./pages/AboutView";
import ContactView from "./pages/ContactView";
import PortfolioView from "./pages/PortfolioView";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <NavBar />
        <AboutView id="aboutView" />
        <PortfolioView id="portfolioView" />
        <ContactView id="contactView" />
        <Footer />
      </ThemeProvider>
    );
  }
}

export default App;
