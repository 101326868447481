import React, { Component, Fragment } from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  option: {
    color: "black",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none"
    }
  },
  optionActive: {
    color: "red",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none"
    }
  }
});

class CategoryLinks extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <Fragment>
        <Typography>
          <Link
            id="all"
            variant="subtitle1"
            className={
              this.props.activeLink === "all"
                ? classes.optionActive
                : classes.option
            }
            onClick={this.props.handleClick}
          >
            All <strong>{this.props.allProjects.length}</strong> Projects
          </Link>
        </Typography>
        <Typography>
          <Link
            id="featured"
            variant="subtitle1"
            className={
              this.props.activeLink === "featured"
                ? classes.optionActive
                : classes.option
            }
            onClick={this.props.handleClick}
          >
            Featured Projects
          </Link>
        </Typography>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CategoryLinks);
