import React, { Component, Fragment } from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  category: {
    color: "grey",
    margin: "7vh 0px 1vh 0px"
  },
  option: {
    color: "black",
    cursor: "pointer",
    textAlign: "left",
    "&:hover": {
      color: "red",
      fontWeight: "900",
      textDecoration: "none"
    }
  },
  optionActive: {
    color: "red",
    cursor: "pointer",
    textAlign: "left",
    fontWeight: "900",
    "&:hover": {
      textDecoration: "none"
    }
  }
});

class CategoryLinks extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <Fragment>
        <Typography>
          <Link
            id="all"
            variant="subtitle1"
            className={
              this.props.activeLink === "all"
                ? classes.optionActive
                : classes.option
            }
            onClick={this.props.handleClick}
          >
            All <strong>{this.props.allProjects.length}</strong> Projects
          </Link>
        </Typography>
        <Typography>
          <Link
            id="featured"
            variant="subtitle1"
            className={
              this.props.activeLink === "featured"
                ? classes.optionActive
                : classes.option
            }
            onClick={this.props.handleClick}
          >
            Featured Projects
          </Link>
        </Typography>
        <Typography variant="subtitle1" className={classes.category}>
          Languages
        </Typography>
        <Typography>
          <Link
            id="javascript"
            variant="subtitle1"
            className={
              this.props.activeLink === "javascript"
                ? classes.optionActive
                : classes.option
            }
            onClick={this.props.handleClick}
          >
            JavaScript
          </Link>
        </Typography>
        <Typography>
          <Link
            id="python"
            variant="subtitle1"
            className={
              this.props.activeLink === "python"
                ? classes.optionActive
                : classes.option
            }
            onClick={this.props.handleClick}
          >
            Python
          </Link>
        </Typography>
        {this.props.activeLink === "python"
          ? this.props.populatePythonFrameworks()
          : ""}
        {this.props.activeLink === "javascript"
          ? this.props.populateJavaScriptFrameworks()
          : ""}
      </Fragment>
    );
  }
}

export default withStyles(styles)(CategoryLinks);
