import React, { Component, Fragment } from "react";
import { Link } from "react-scroll";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import resume from "../../resume.json";
import "./NavBar.css";

const drawerWidth = "100%";

const styles = theme => ({
  appBar: {
    background: "black",
    borderBottom: "3px solid white",
    color: "red",
    height: "66px",
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  button: {
    color: "white",
    border: "3px solid white",
    display: "inline-block",
    fontSize: theme.typography.h6.fontSize,
    margin: "0px 50px 0px 30px",
    padding: "0px 15px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "red",
      border: "3px solid red"
    }
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    background: "black",
    padding: "30px 0px",
    width: drawerWidth
  },
  listContainer: {
    listStyle: "none",
    margin: 0,
    overflow: "hidden",
    padding: 0
  },
  listItems: {
    color: "white",
    display: "inline-block",
    fontSize: theme.typography.subtitle2.fontSize,
    margin: "0px 30px",
    textAlign: "center",
    "&:hover": {
      color: "red"
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  rootRight: {
    display: "flex",
    position: "absolute",
    right: 0
  },
  toolbar: theme.mixins.toolbar
});

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const classes = this.props.classes;
    return (
      <Fragment>
        <CssBaseline />
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div className={classes.rootRight}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Hidden smDown implementation="css">
                <ul className={classes.listContainer}>
                  <li className="appBarItem">
                    <Link
                      activeClass="active"
                      to="aboutView"
                      spy={true}
                      smooth={true}
                      offset={-63}
                      duration={500}
                      className={classes.listItems}
                    >
                      ABOUT
                    </Link>
                  </li>
                  <li className="appBarItem">
                    <Link
                      activeClass="active"
                      to="portfolioView"
                      spy={true}
                      smooth={true}
                      offset={-63}
                      duration={500}
                      className={classes.listItems}
                    >
                      PORTFOLIO
                    </Link>
                  </li>
                  <li className="appBarItem">
                    <Link
                      activeClass="active"
                      to="contactView"
                      spy={true}
                      smooth={true}
                      offset={-63}
                      duration={500}
                      className={classes.listItems}
                    >
                      CONTACT
                    </Link>
                  </li>
                  <li className="appBarItem">
                    <Button href={resume.pdf} className={classes.button}>
                      RESUME
                    </Button>
                  </li>
                </ul>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="persistent"
            anchor="top"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <div className={classes.toolbar} />
            <List>
              <ListItem className="drawerItem">
                <Link
                  activeClass="active"
                  to="aboutView"
                  spy={true}
                  smooth={true}
                  offset={-63}
                  duration={500}
                  className={classes.listItems}
                  onClick={this.handleDrawerToggle}
                >
                  ABOUT
                </Link>
              </ListItem>
              <ListItem className="drawerItem">
                <Link
                  activeClass="active"
                  to="portfolioView"
                  spy={true}
                  smooth={true}
                  offset={-63}
                  duration={500}
                  className={classes.listItems}
                  onClick={this.handleDrawerToggle}
                >
                  PORTFOLIO
                </Link>
              </ListItem>
              <ListItem className="drawerItem">
                <Link
                  activeClass="active"
                  to="contactView"
                  spy={true}
                  smooth={true}
                  offset={-63}
                  duration={500}
                  className={classes.listItems}
                  onClick={this.handleDrawerToggle}
                >
                  CONTACT
                </Link>
              </ListItem>
            </List>
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NavBar);
