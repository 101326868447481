import React, { Component, Fragment } from "react";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  category: {
    color: "grey",
    margin: "7vh 0px 1vh 0px"
  },
  checkbox: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    border: "1px solid black",
    borderRadius: 3,
    width: 16,
    height: 16,
    backgroundColor: "white",
    "input:hover ~ &": {
      backgroundColor: "white"
    }
  },
  checkedIcon: {
    backgroundColor: "red",
    "&:before": {
      display: "block",
      width: 14,
      height: 14,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "red"
    }
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize
  }
});

class JavaScriptCheckbox extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <Fragment>
        <Typography variant="subtitle1" className={classes.category}>
          Technologies
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                color="default"
                value="nodejs"
                checked={this.props.nodejs}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                className={classes.checkbox}
                icon={<span className={classes.icon} />}
                onChange={this.props.handleChange("nodejs")}
              />
            }
            label="Node.JS"
            className={classes.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                disableRipple
                value="react"
                checked={this.props.react}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                className={classes.checkbox}
                icon={<span className={classes.icon} />}
                onChange={this.props.handleChange("react")}
              />
            }
            label="React"
            className={classes.label}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                disableRipple
                value="vanillajs"
                checked={this.props.vanillajs}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                className={classes.checkbox}
                icon={<span className={classes.icon} />}
                onChange={this.props.handleChange("vanillajs")}
              />
            }
            label="Vanilla (None)"
            className={classes.label}
          />
        </FormGroup>
      </Fragment>
    );
  }
}

export default withStyles(styles)(JavaScriptCheckbox);
