import React, { Component } from "react";
import emailjs from "emailjs-com";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Form from "../components/Form";

const styles = () => ({
  background: {
    background: "black",
    color: "white"
  },
  container: {
    minHeight: "calc(100vh - 213px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center"
  },
  gridTop: { marginTop: "7vh" },
  red: {
    color: "red"
  },
  text: {
    marginBottom: "2vh",
    fontWeight: "bold"
  },
  textField: {
    background: "white",
    "& input": {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "10px",
      color: "black"
    },
    "& label": {
      color: "black",
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    "& label.Mui-focused": {
      color: "red"
    },
    "& textarea": {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "10px",
      color: "black"
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderColor: "white"
      },
      "&:after": {
        border: "2px solid red"
      }
    }
  },
  title: {
    marginBottom: "4vh",
    textDecoration: "underline"
  }
});

class ContactView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: "",
        email: "",
        message: ""
      },
      emailSuccess: false,
      emailError: false
    };
  }

  emailRef = React.createRef();

  handleChange = event => {
    event.preventDefault();
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleBlur = event => {
    this.emailRef.current.validate(event.target.value);
  };

  handleSubmit = () => {
    const templateParams = {
      user_name: this.state.formData.name,
      user_email: this.state.formData.email,
      message: this.state.formData.message
    };
    emailjs
      .send(
        "outlook",
        "portfolio_template",
        templateParams,
        "user_KzSfhRJBVOIjgpVX7s1Cv"
      )
      .then(
        response => {
          this.setState({
            emailSuccess: true,
            emailError: false
          });
        },
        err => {
          this.setState({
            emailSuccess: false,
            emailError: true
          });
        }
      );
  };

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.background} id={this.props.id}>
        <Container maxWidth="md" className={classes.container}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridTop}
          >
            <Grid item xs={12}>
              <Typography
                align="center"
                display="block"
                variant="h3"
                className={classes.title}
              >
                CONTACT
              </Typography>
              <Typography
                align="center"
                display="block"
                variant="body2"
                className={classes.text}
              >
                Have a question or want to collaborate? <br /> Feel free to
                email me at{" "}
                <span className={classes.red}>adishthapa@outlook.com</span> or
                submit the form below:
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Form
                name={this.state.formData.name}
                email={this.state.formData.email}
                message={this.state.formData.message}
                emailSuccess={this.state.emailSuccess}
                emailError={this.state.emailError}
                emailRef={this.emailRef}
                handleBlur={this.handleBlur}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(ContactView);
