import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import GitHub from "@material-ui/icons/GitHub";
import LinkedIn from "@material-ui/icons/LinkedIn";

const styles = theme => ({
  copyright: {
    color: "red"
  },
  fab: {
    background: "black",
    borderRadius: 0,
    color: "white",
    margin: "0px 15px",
    "&:hover": {
      background: "white",
      color: "red"
    }
  },
  footer: {
    alignItems: "center",
    backgroundColor: "white",
    display: "flex",
    height: "150px",
    justifyContent: "center"
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    margin: "15px"
  }
});

class Footer extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <Fragment>
        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <div className={classes.icons}>
              <Fab
                aria-label="github"
                href="https://github.com/adishthapa"
                className={classes.fab}
              >
                <GitHub fontSize="large" />
              </Fab>
              <Fab
                aria-label="linkedin"
                href="https://linkedin.com/in/adish-thapa"
                className={classes.fab}
              >
                <LinkedIn fontSize="large" />
              </Fab>
            </div>
            <Typography align="center" display="block" variant="overline">
              ADISH THAPA{" "}
              <span className={classes.copyright}>
                © {new Date().getFullYear()}{" "}
              </span>
            </Typography>
          </Container>
        </footer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Footer);
