import React, { Component } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import GitHub from "@material-ui/icons/GitHub";
import LaunchIcon from "@material-ui/icons/Launch";

const styles = theme => ({
  paper: {
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  description: {
    marginTop: "10px"
  },
  fab1: {
    background: "black",
    color: "white",
    marginLeft: "15px",
    "&:hover": {
      background: "white",
      color: "red"
    }
  },
  fab2: {
    background: "black",
    color: "white",
    marginLeft: "15px",
    "&:hover": {
      background: "red"
    }
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px"
  },
  icon: {
    margin: "0px 10px"
  },
  iconClose: {
    borderLeft: "2px solid black",
    display: "inline-block",
    marginLeft: "30px",
    paddingLeft: "15px"
  },
  technology: {
    background: "black",
    color: "white",
    display: "inline-block",
    fontWeight: "bold",
    padding: "10px",
    marginRight: "10px",
    marginBottom: "10px"
  },
  technologyList: {
    marginBottom: "25px",
    marginTop: "25px"
  },
  name: {
    marginBottom: "10px"
  }
});

class Modal extends Component {
  render() {
    const classes = this.props.classes;

    return (
      <Dialog
        className={classes.dialog}
        open={this.props.dialogOpen}
        onClose={this.props.handleDialogClose}
        disableScrollLock
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 10
        }}
      >
        <Fade in={this.props.dialogOpen}>
          <Grid container className={classes.paper}>
            <Grid item xs={12} className={classes.header}>
              <div>
                {this.props.activeProject.githubLink ? (
                  <Fab
                    size="small"
                    href={this.props.activeProject.githubLink}
                    className={classes.fab1}
                  >
                    <GitHub />
                  </Fab>
                ) : (
                  ""
                )}
                {this.props.activeProject.deployedLink ? (
                  <Fab
                    size="small"
                    href={this.props.activeProject.deployedLink}
                    className={classes.fab1}
                  >
                    <LaunchIcon />
                  </Fab>
                ) : (
                  ""
                )}
                <div className={classes.iconClose}>
                  <Fab
                    size="small"
                    className={classes.fab2}
                    onClick={this.props.handleDialogClose}
                  >
                    <Close />
                  </Fab>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.name}>
              <Typography align="left" variant="h4">
                {this.props.activeProject.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Typography
                align="left"
                display="block"
                variant="body1"
                className={classes.description}
              >
                {this.props.activeProject.description}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.technologyList}>
              {this.props.activeProject.technologies
                ? this.props.activeProject.technologies.map(
                    (technology, index) => (
                      <Typography
                        key={technology}
                        align="left"
                        variant="caption"
                        className={classes.technology}
                      >
                        {technology}
                      </Typography>
                    )
                  )
                : ""}
            </Grid>
          </Grid>
        </Fade>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Modal);
