import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const styles = theme => ({
  button: {
    border: "3px solid white",
    color: "white",
    display: "inline-block",
    fontSize: theme.typography.h6.fontSize,
    marginTop: "11px",
    marginBottom: "5vh",
    textAlign: "center",
    width: "33.33%",
    "&:hover": {
      backgroundColor: "red",
      border: "3px solid red"
    }
  },
  emailError: {
    background: "red",
    marginTop: "8px",
    textAlign: "center",
    width: "100%"
  },
  emailSuccess: {
    background: "green",
    marginTop: "8px",
    textAlign: "center",
    width: "100%"
  },
  form: {
    alignItems: "center"
  },
  formButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  textField: {
    background: "white",
    "& input": {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "10px",
      color: "black"
    },
    "& label": {
      color: "black",
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "black"
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "red"
    },
    "& textarea": {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "10px",
      color: "black"
    },
    "& .MuiInput-underline": {
      "&:before": {
        borderColor: "#BFBFBF"
      },
      "&:after": {
        border: "2px solid black"
      }
    },
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: "10px",
      marginBottom: "4px"
    }
  }
});

class Form extends Component {
  render() {
    const classes = this.props.classes;

    return (
      <ValidatorForm
        ref="form"
        instantValidate={false}
        onSubmit={this.props.handleSubmit}
      >
        <TextValidator
          label="Name"
          margin="dense"
          name="name"
          className={classes.textField}
          fullWidth={true}
          onChange={this.props.handleChange}
          value={this.props.name}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
        <TextValidator
          ref={this.props.emailRef}
          label="Email"
          margin="dense"
          name="email"
          className={classes.textField}
          fullWidth={true}
          onBlur={this.props.handleBlur}
          onChange={this.props.handleChange}
          value={this.props.email}
          validators={["required", "isEmail"]}
          errorMessages={["This field is required", "This email is not valid"]}
        />
        <TextValidator
          label="Message"
          margin="dense"
          name="message"
          rows="3"
          multiline
          className={classes.textField}
          fullWidth={true}
          onChange={this.props.handleChange}
          value={this.props.message}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
        {this.props.emailSuccess ? (
          <Typography
            align="center"
            display="block"
            variant="body1"
            className={classes.emailSuccess}
          >
            Your message was sent successfully. Thanks!
          </Typography>
        ) : (
          ""
        )}

        {this.props.emailError ? (
          <Typography
            align="center"
            display="block"
            variant="body1"
            className={classes.emailError}
          >
            Sorry there was an error. Please try again later.
          </Typography>
        ) : (
          ""
        )}

        <div className={classes.formButton}>
          <Button type="submit" className={classes.button}>
            Submit
          </Button>
        </div>
      </ValidatorForm>
    );
  }
}

export default withStyles(styles)(Form);
